import VueRouter from "vue-router";
import Vue from "vue";
import store from "./store";
import { createRouter, createWebHistory } from "vue-router";
import Toaster from "v-toaster"; // or whatever library you're using for toast

Vue.use(Toaster);

/**
 import { createRouter, createWebHashHistory } from 'vue-router'

  const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    //...
  ],
})

Or......................

const router = VueRouter.createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: VueRouter.createWebHashHistory(),
  routes, // short for `routes: routes`
})


 */

const routes = [
  {
    path: "/",
    redirect: "/login",
    meta: { title: "Login" },
    component: () => import("./Pages/auth/login.vue"),
  },
  {
    path: "/login",
    meta: { title: "Login" },
    component: () => import("./Pages/auth/login.vue"),
  },
  {
    path: "/register/:token",
    name: "register",
    meta: { title: "Register" },
    component: () => import("./Pages/auth/register.vue"),
  },
  {
    path: "/password/reset/:token",
    meta: { title: "Reset Password" },
    // component: () => import("./Pages/auth/ResetPasswordComponent.vue"),
    component: () => import("./Pages/auth/resetPassword.vue"),
  },
  {
    path: "/logout",
    meta: { title: "Logout" },
    component: () => import("./Pages/auth/logout.vue"),
  },
  {
    path: "/dashboard",
    meta: { requiresAuth: true, title: "Dashboard" },
    component: () => import("./layouts/default-page.vue"),
    redirect: "/dashboard/home",
    children: [
      {
        path: "/dashboard/home",
        name: "Dashboard",
        // meta: { title: "Dashboard" },
        component: () => import("./Pages/dashboard/dashboard.vue"),
        // component: () => import("./Pages/customer/list.vue"),
      },
      {
        path: "/dashboard/customer",
        name: "Customer",
        // meta: { title: "Customer" },
        component: () => import("./Pages/customer/list.vue"),
      },
      {
        path: "/dashboard/customer/add",
        name: "Add Customer",
        component: () => import("./Pages/customer/add.vue"),
      },
      {
        path: "/dashboard/customer/edit/:id",
        name: "Edit Customer",
        component: () => import("./Pages/customer/detail.vue"),
      },
      // Delivery-Man
      {
        path: "/dashboard/agency-delivery-man",
        name: "DeliveryMan",
        component: () => import("./Pages/deliveryMan/list.vue"),
      },
      {
        path: "/dashboard/agency-delivery-man/add",
        name: "Add DeliveryMan",
        component: () => import("./Pages/deliveryMan/add.vue"),
      },
      {
        path: "/dashboard/agency-delivery-man/edit/:id",
        name: "Edit DeliveryMan",
        component: () => import("./Pages/deliveryMan/add.vue"),
      },

      // Delivery
      {
        path: "/dashboard/delivery",
        name: "Delivery List",
        component: () => import("./Pages/delivery/list.vue"),
      },
      {
        path: "/dashboard/delivery/add",
        name: "Add Delivery",
        component: () => import("./Pages/delivery/form.vue"),
      },
      {
        path: "/dashboard/delivery/edit/:id",
        name: "Edit Delivery",
        component: () => import("./Pages/delivery/edit.vue"),
      },
      {
        path: "/dashboard/delivery/view:id",
        name: "View Delivery",
        component: () => import("./Pages/delivery/view.vue"),
      },

      // cylinders
      {
        path: "/dashboard/cylinders",
        name: "Cylinders",
        component: () => import("./Pages/cylinders/list.vue"),
      },
      {
        path: "/dashboard/vehicle",
        name: "Vehicle",
        component: () => import("./Pages/vehicle/list.vue"),
      },
      {
        path: "/dashboard/regulators",
        name: "Regulators",
        component: () => import("./Pages/regulator/list.vue"),
      },
      {
        path: "/dashboard/hoses",
        name: "Hoses",
        component: () => import("./Pages/hose_pipe/list.vue"),
      },

      // Invoice
      {
        path: "/dashboard/invoice",
        name: "Invoice",
        component: () => import("./Pages/invoice/list.vue"),
      },
      {
        path: "/dashboard/invoice/add/",
        name: "Add Invoice",
        component: () => import("./Pages/invoice/form.vue"),
      },
      {
        path: "/dashboard/invoice/view/:id",
        name: "View Invoice",
        component: () => import("./Pages/invoice/view.vue"),
      },
      // {
      //   path: "/dashboard/invoice/edit/:id",
      //   name: "Edit Invoice",
      //   component: () => import("./Pages/invoice/edit.vue"),
      // },

      // Setting
      {
        path: "/dashboard/settings",
        name: "Setting",
        component: () => import("./Pages/setting/form.vue"),
      },
      {
        path: "/dashboard/supplier",
        name: "Supplier",
        component: () => import("./Pages/supplier/list.vue"),
      },
      {
        path: "/dashboard/supplier/add",
        name: "Add Supplier",
        component: () => import("./Pages/supplier/add.vue"),
      },
      {
        path: "/dashboard/supplier/edit/:id",
        name: "View Supplier",
        component: () => import("./Pages/supplier/detail.vue"),
      },
      {
        path: "/dashboard/load-received",
        name: "Load Received",
        component: () => import("./Pages/supply/list.vue"),
      },
      {
        path: "/dashboard/load-received/add",
        name: "Add Load Received",
        component: () => import("./Pages/supply/form.vue"),
      },
      {
        path: "/dashboard/load-received/edit/:id",
        name: "View Load Received",
        component: () => import("./Pages/supply/view.vue"),
      },
      {
        path: "/dashboard/reports",
        name: "Reports",
        component: () => import("./Pages/reports/list.vue"),
      },
    ],
  },

  {
    path: "/admin",
    meta: { requiresAuth: true },
    component: () => import("./layouts/superAdminLayout.vue"),
    redirect: "/admin/distributors",
    children: [
      // Distributors
      {
        path: "/admin/distributors",
        meta: { isAdminOnly: true },
        name: "Distributors",
        component: () => import("./Pages/superAdmin/distributors/list.vue"),
      },
      {
        path: "/admin/distributor/add",
        meta: { isAdminOnly: true },
        name: "Add Distributor",
        component: () => import("./Pages/superAdmin/distributors/add.vue"),
      },
      {
        path: "/admin/distributor/edit/:id",
        meta: { isAdminOnly: true },
        name: "Edit Distributor",
        component: () => import("./Pages/superAdmin/distributors/add.vue"),
      },
      // Profile

      {
        path: "/admin/profile",
        meta: { isAdminOnly: true },
        name: "Profile",
        component: () => import("./Pages/superAdmin/authPages/profile.vue"),
      },

      // Services
      {
        path: "/admin/services",
        meta: { isAdminOnly: true },
        name: "Services",
        component: () => import("./Pages/superAdmin/services/list.vue"),
      },
      {
        path: "/admin/service/add",
        meta: { isAdminOnly: true },
        name: "Add Service",
        component: () => import("./Pages/superAdmin/services/add.vue"),
      },
      {
        path: "/admin/service/edit/:id",
        meta: { isAdminOnly: true },
        name: "Edit Service",
        component: () => import("./Pages/superAdmin/services/add.vue"),
      },

      // Plans
      {
        path: "/admin/plans",
        meta: { isAdminOnly: true },
        name: "Plans",
        component: () => import("./Pages/superAdmin/plans/list.vue"),
      },
      {
        path: "/admin/plan/add",
        meta: { isAdminOnly: true },
        name: "Add Plan",
        component: () => import("./Pages/superAdmin/plans/add.vue"),
      },
      {
        path: "/admin/plan/edit/:id",
        meta: { isAdminOnly: true },
        name: "Edit Plan",
        component: () => import("./Pages/superAdmin/plans/add.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes, // short for routes: routes
  // linkExactActiveClass: "nav-item active router-link-active",
  linkExactActiveClass: "router-link-active", // set your specific class instead of "nav-item active"
  mode: "hash", //"'hash' | 'history' | 'abstract'", //RouterMode = mode
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        } else {
          resolve({ x: 0, y: 0 });
        }
      }, 500);
    });
  },
});
// TODO :: Toaster message not display due to instance not available::
router.beforeEach(async (to, from, next) => {
  console.log("==> PATH : to", to);
  try {
    document.title = to.meta.title || to.name || "Stockbook"; // Default Title in case no title is set
    if (to.matched.some((m) => m.meta.requiresAuth)) {
      if (store.getters.getAuthStatus == false) {
        console.log("You Are Not Authorised For this Route");
        alert("You Are Not Authorised For this Path");
        // this.$toaster.error("You Are Not Authorised For this Route");
        return next({ path: "/login" });
      }
    } else if (to.matched.some((m) => m.meta.guestOnly)) {
      try {
        if (store.getters.getAuthStatus == true) {
          // this.$toaster.error("You Are Not Authorised For this Route");
          // Vue.toasted.error("You are already logged in.");
          alert("You Are Not Authorised For this Path");
          return next({ path: "/dashboard" });
        }
      } catch (error) {
        // this.$toaster.error(error);
        console.log("error: ", error);
        // Vue.toasted.error(e.message);
      }
    }
    if (to.matched.some((m) => m.meta.isAdminOnly)) {
      try {
        console.log(
          "store.getters.isSuperAdminOnly >>>",
          store.getters.isSuperAdminOnly
        );
        if (!store.getters.isSuperAdminOnly == true) {
          console.log("You Are Not Authorised For this Route");
          alert("You Are Not Authorised For this Path");
          return next({ path: "/dashboard" });
        }
      } catch (error) {
        console.log("error:", error);
        // Vue.toasted.error(e.message);
      }
    }
    return next();
  } catch (e) {
    console.log("error:", e);
    // Vue.toasted.error(e.message);
  }
});

export default router;
