///// DISTRIBUTER SERVICES CONSTANT

export const DISTRIBUTER_SERVICES = {
  WHATSAPP_SERVICE: "whatsapp_service",
  REPORT_SERVICE: "report_service",
  DELIVERYMAN_SERVICE: "deliveryman_service",
  // E_INVOICE_SERVICE: "e_invoice_service",
  // E_WAY_INVOICE_SERVICE: "e_way_invoice_service",
  E_WAYBILLING_AND_E_INVOICE_SERVICE: "e_way_billing_and_e_invoice_service",
  BLACK_LABEL_SERVICE: "black_label_service",
  INVENTORY_SERVICE: "inventory_service",

};

export const USER_TYPES = {
  DISTRIBUTER: 1,
  DELIVERYMAN: 2,
  SUPER_ADMIN: 3,
};

export const INVOICE_TYPE = [
  { code: "COMMERCIAL", id: 1, title: "Commercial" },
  { code: "NON_COMMERCIAL", id: 2, title: "Domestic" },
];

export const INDIAN_STATE = [
  {
    state_id: 1,
    state_code: "UP",
    state_name: "Uttar Pradesh",
  },
  {
    state_id: 2,
    state_code: "MH",
    state_name: "Maharashtra",
  },
  {
    state_id: 3,
    state_code: "BR",
    state_name: "Bihar",
  },
  {
    state_id: 4,
    state_code: "WB",
    state_name: "West Bengal",
  },
  {
    state_id: 5,
    state_code: "MP",
    state_name: "Madhya Pradesh",
  },
  {
    state_id: 6,
    state_code: "TN",
    state_name: "Tamil Nadu",
  },
  {
    state_id: 7,
    state_code: "RJ",
    state_name: "Rajasthan",
  },
  {
    state_id: 8,
    state_code: "KA",
    state_name: "Karnataka",
  },
  {
    state_id: 9,
    state_code: "GJ",
    state_name: "Gujarat",
  },
  {
    state_id: 10,
    state_code: "AP",
    state_name: "Andhra Pradesh",
  },
];
