import Vue from "vue";
import Chartist from "chartist";
import VueRouter from "vue-router";
import router from "./routes";
import store from "./store";
import axios from "axios";
// import VueSocketio from "vue-socket.io";
// import socketio from "socket.io-client";
import { io } from "socket.io-client";

import DashboardPlugin from "./material-dashboard";
import App from "./App.vue";
import VeeValidate from "vee-validate";
// import * as VeeValidate from 'vee-validate';
// import toastr from "toastr";

import Toaster from "v-toaster";
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import "v-toaster/dist/v-toaster.css";
// optional set default timeout, the default is 10000 (10 seconds).
Vue.use(Toaster, { timeout: 5000 });

import moment from "moment";
import Form from "./services/form";
import helper from "./services/helper";
import "./assets/scss/style.scss";

window.Form = Form;
window.helper = helper;
// window.toastr = toastr;
window.toaster = Toaster;
window.moment = moment;
window.EventBus = new Vue();

//lodash
window._get = require("lodash/get");
window._eachRight = require("lodash/eachRight");
window._replace = require("lodash/replace");
window._has = require("lodash/has");
window._size = require("lodash/size");
window._cloneDeep = require("lodash/cloneDeep");

// Network URL
// let baseurl = "http://192.168.1.24:3001/";

// http://192.168.1.24:3001/api

window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.baseURL = baseurl;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
window.axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("auth_token");

//echo
window.Pusher = require("pusher-js");

// Replace 'http://localhost:YOUR_PORT' with your Socket.IO server
// const socket = socketio("http://65.1.162.146:3001");
// Vue.prototype.$socket = socket;
// Vue.use(socket);

Vue.prototype.$Chartist = Chartist;
Vue.use(VueRouter);
Vue.use(VeeValidate);
// Vue.use(VeeValidate, { fieldsBagName: "veeFields" });
Vue.use(DashboardPlugin);

new Vue({
  el: "#app",
  render: (h) => h(App),
  store,
  // VeeValidate,
  router,
  data: {
    Chartist: Chartist,
  },
});

// Vue.use(VueSocketio);

// Vue.use(new VueSocketio({}));
