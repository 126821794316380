<template>
  
  <div
  class="sidebar"
  :data-color="activeColor"
  :data-image="backgroundImage"
  :data-background-color="backgroundColor"
  :style="sidebarStyle"
  >
    <div class="logo d-flex align-items-center">
      <a href="/#/dashboard/home" class="simple-text logo-normal pl-3">
        <template v-if="$route.meta.rtlActive">{{ rtlTitle }}
        </template>
        <template v-else>
          <div class="stock-logo">
            <img src="../../assets/images/stock-book-logo.svg" alt="Logo" />
          </div>
          
        </template>
      </a>
        <div class="minimaize-logo" v-if="this.$sidebar.isMinimized">
          <a href="/#/dashboard/home">
            <img src="../../assets/images/loder.png" alt="Logo">
          </a>
        </div>
      <div class="navbar-minimize">
        <a
          id="minimizeSidebar"
          class="md-round md-just-icon md-transparent pointer"
          @click="minimizeSidebar"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular"
            >more_vert</i
          >
          <i
            class="material-icons design_bullet-list-67 visible-on-sidebar-mini"
            >view_list</i
          >
        </a>
      </div>
    </div>
    <!-- <div class="mini-sidebar"> -->
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
      </div>
      <!-- </div> -->
  </div>

</template>
<script>
export default {
  name: "sidebar",
  props: {
    title: {
      type: String,
      default: "Stock Book",
    },
    rtlTitle: {
      type: String,
      default: "توقيت الإبداعية",
    },
    activeColor: {
      type: String,
      default: "green",
      validator: (value) => {
        let acceptedValues = [
          "",
          "purple",
          "azure",
          "green",
          "orange",
          "danger",
          "rose",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundImage: {
      type: String,
      default: "../../assets/images/loder.png",
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: (value) => {
        let acceptedValues = ["", "black", "white", "red"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: "/images/logo.png",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
        
      }
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      };
    },
    getTitle() {
      return this.$store.state.setting.banner_name || "Set Title";
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
.simple-text {
  white-space: normal !important;
}
.stock-logo {
  width: 126px;
  margin-left: 15px;
}
/* .sidebar{
  position: relative;
} */
/* .main-sidebar{
  position: relative; */
  /* content: ""; */
  /* height: 100vh;
  width: 100vw;
  right: 0px;
  top: 0px; */
/* } */
 .mini-sidebar{
  position: absolute;
  height: 100vh;
  width: 100vw;
  right: 0px;
  top: 0px; 
 
}

.minimaize-logo{
  width: 48px;
  position: absolute;
  right: 10%;
}
.sidebar:hover .minimaize-logo img{
 display: none;
}
/* .md-button:hover{
  color: #606a76 !important;
} */
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
