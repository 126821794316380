<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import io from "socket.io-client";

const URL =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_BASE_URL
    : "http://65.1.162.146:3001";

export default {
  data() {
    return {
      connected: false,
      fooEvents: [],
      barEvents: [],
      socket: null,
    };
  },
  //
  mounted() {
    if (this.$store?.state?.distributorID) {
      axios.defaults.headers.common["distributor_id"] =
        this.$store?.state?.distributorID;
    }
    this.initiateSocket();
  },
  beforeUnmount() {
    // Use disconnect event to avoid memory leak
    this.socket.disconnect();
  },
  methods: {
    logout() {
      this.$router.push("/login");
      helper.logout().then((response) => {
        this.$store.dispatch("resetAuthUserDetail");
        localStorage.removeItem("pcode_id");
      });
    },

    initiateSocket() {
      this.socket = io(URL, { autoConnect: false });

      this.socket.connect();

      this.socket.on("connect", () => {
        this.connected = true;
      });

      this.socket.on("disconnect", () => {
        this.connected = false;
      });

      this.socket.on("foo", (...args) => {
        this.fooEvents.push(args);
      });

      this.socket.on("bar", (...args) => {
        this.barEvents.push(args);
      });

      this.socket.on("FORCE_LOGOUT", (user) => {
        console.log("Force Logout for user: ", user);
        // Perform Logout operations here
        console.log("user.distributor >>>", user.distributor);
        console.log(
          "this.$store?.state?.distributorID >>>",
          this.$store?.state?.distributorID
        );
        if (user.distributor === this.$store?.state?.distributorID) {
          console.log("user >>>", user);
          this.logout();
        }
      });

      // here rizwan
      this.socket.on("NEGATIVE_STOCK", (stockInstance) => {
      
        if (stockInstance?.id === this.$store?.state?.userData?.id) {
          this.$store.dispatch("setAllowNagativeStockForDistributor", stockInstance || {});
        }
      });
      
    },
  },
};
/*

export default {
  mounted() {
    if (this.$store?.state?.distributorID) {
      axios.defaults.headers.common["distributor_id"] =
        this.$store?.state?.distributorID;
    }
  },
};
*/
</script>
